import Dashboard from 'components/core/Dashboard'

export default (props) => {
  
  return (
    <Dashboard 
      title="Page Not Found">

          <div>
              Oops. Page is not found
          </div>
        
    </Dashboard>
  )
}